import React from 'react'
import { NavLink } from 'react-router-dom';
import { routes } from '../routes';

function Menu({isOpen, onClose}) {
    if(!isOpen) return null;
    
    return (
        <div className="main-menu">
            <div className="main-menu-content">
                <NavLink to={routes.home} onClick={onClose}><img src="img/10a-logo-white.svg" className="logo" alt="10A Logo" /></NavLink>
                <NavLink to={routes.home} onClick={onClose} className="main-menu-link">Home</NavLink>
                <NavLink to={routes.about} onClick={onClose} className="main-menu-link">About us</NavLink>
                <NavLink to={routes.wedo} onClick={onClose} className="main-menu-link">Stuff we do</NavLink>
                <NavLink to={routes.career} onClick={onClose} className="main-menu-link">Careers</NavLink>
                <NavLink to={routes.contact} onClick={onClose} className="main-menu-link">Contact</NavLink>
            </div>

            <div className="main-ilu-container">
                <div className="main-ilu">
                    <img src="img/main-ilu-a.svg" alt="10A HQ Illustration A" className="main-ilu-img-a" draggable="false" />
                    <img src="img/main-ilu-0.svg" alt="10A HQ Illustration 0" className="main-ilu-img-0" draggable="false" />
                    <img src="img/main-ilu-1.svg"  alt="10A HQ Illustration 1" className="main-ilu-img-1" draggable="false" />
                    <img src="img/main-ilu.svg"   alt="10A HQ Illustration" className="main-ilu-img" draggable="false" />
                </div>
            </div>

            <button onClick={onClose} className="menu-btn">
                <i className="fa fa-times"></i>
            </button>
        </div>
    )
}

export default Menu
