import React from 'react';
import Portfolio from '../components/Portfolio';
import TrustedBy from '../components/TrustedBy';
import WeAreExperts from '../components/WeAreExperts';
import MapContainer from '../components/MapContainer';


function Contact() {

    

    return (
        <>
            <div className="section-photo section-photo-right contact-page-data">
                <div className="section-image right">
                    <div id="google-map">
                        <MapContainer/>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="container-resp">
                    <div className="col1-2 section-photo-content">
                        <h2>Drop us a line at <a href="mailto:hello@10a.io">hello@10a.io</a>!</h2>
                        <h5>Main office:</h5>
                        <p>Dekerta 47, 30-703 Krakow, Poland</p>
                        <h5>Registration data:</h5>
                        <p>Zalass Consulting sp. z o.o., Królewska 2/2, 30-045 Krakow, Poland</p>
                        <p className="small">NIP (Tax Identification No.): 6772374198</p>
                        <p className="small">Register of entrepreneurs of the National Court Register, kept by the District Court for Kraków-Śródmieście in Kraków, 11th Commercial Department of the National Court Register, under no. KRS 0000454485, with a share capital of PLN 25 700.</p>

                    </div>
                    </div>
                </div>
            </div>


            <TrustedBy />

            <WeAreExperts />

            <Portfolio />
        </>
    )
}

export default Contact
