import React from 'react'
import Portfolio from '../components/Portfolio'

function Wedo() {
    return (
        <>
            <div className="sections-ilu">

                <div className="section-ilu">
                    <div className="wrapper">
                        <img src="img/dedicated-team-ilu.svg" alt="Dedicated Solutions Illustration" />
                        <div>
                            <h2>Dedicated teams</h2>
                            <p>We provide a complex approach from designing a concept to implementing a digital product as well as specific support for your team or your product in a selected area, including team leasing and outsourcing.</p>
                        </div>
                    </div>
                </div>

                <div className="section-ilu">
                    <div className="wrapper">
                        <img src="img/development-ilu.svg" alt="Dedicated Solutions Illustration" />
                        <div>
                            <h2>Digital products</h2>
                            <p>We create web and mobile applications. We connect services into systems and develop custom integrations for business processes, including hardware-based solutions. We love new products, but we also have experience guiding companies through the process of software modernization and maintenance.</p>
                            <h4>Technologies we use</h4>
                            <p>We specialize mostly in Java, React.js, React Native, Node.js, and PHP Laravel. We also have experts in DevOps and Administration areas. We have experience developing solutions based on both machine learning and OR-Tools, as well as hardware integrations.</p>
                        </div>
                    </div>
                </div>

                <div className="section-ilu">
                    <div className="wrapper">
                        <img src="img/design-ilu.svg" alt="Dedicated Solutions Illustration" />
                        <div>
                            <h2>Design</h2>
                            <p>We see design not as a part of the process, but as a foundation of a product. We provide services in the area of product design, including workshops, UX solutions, prototyping, and UI design. We help build connections between design and development by implementing design systems.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="section-ilu">
                    <div className="wrapper">
                        <img src="img/consulting-ilu.svg" alt="Dedicated Solutions Illustration" />
                        <div>
                            <h2>Consulting</h2>
                            <p>We like to share our knowledge by consulting, mentoring, and conducting audits. Feeling lost in the digital transformation of your organization? You've come to the right place.</p>
                        </div>
                    </div>
                </div>
            </div>

            <Portfolio />
        </>
    )
}

export default Wedo
