import React from 'react'
import TrustedBy from '../components/TrustedBy'
import {NavLink} from 'react-router-dom';
import {routes} from '../routes/index';
import {Slide} from 'react-slideshow-image';

const images = [
    {url:'img/photos/15.jpg'},
    {url:'img/photos/11.jpg'},
    {url:'img/photos/12.jpg'},
    {url:'img/photos/13.jpg'},
    {url:'img/photos/14.jpg'},
];

function About() {
    return (
        <>
            <div className="section-photo section-photo-right section-photo-carousel">
                <div className="slider">
                    <Slide
                        arrows ={false}
                        cssClass="slider-wrapp"
                        duration={6000}
                        easing="ease"
                        transitionDuration={500}
                    >
                        {images.map((image, index) => (
                            <div className="each-slide" key={index}>
                                <div style={{'backgroundImage': `url(${image.url})`}}>
                            </div>
                        </div>
                        ))}
                    </Slide>
                </div>

                <div className="wrapper">
                    <div className="container-resp">
                    <div className="col1-2 section-photo-content">
                        <h2>Talented team</h2>
                        <p>10A is nearly 30 people with exceptional talent and enthusiasm. We focus on long-term relationships and create our plans and reality together. We make sure that as we grow, we don't lose the advantages of being an agile organization focused on the main goal - delivering human-centered digital products.
                        </p>
                        <div className="tags">
                            <div><span role="img" aria-label='emoji'>🧑‍🚀</span> 25+ Humans</div>
                            <div><span role="img" aria-label='emoji'>🐕</span> 4+ Dogs</div>
                            <div><span role="img" aria-label='emoji'>🏙</span> Krakow, Poland</div>️
                        </div>
                    </div>
                    </div>
                </div>

            </div>

            <div className="section-photo section-photo-left">
                <div className="section-image left" style={{backgroundImage: `url('img/photos/3.jpg')`}}>
                </div>
                <div className="wrapper">
                    <div className="container-resp">
                        <div className="col1-2 section-photo-content">
                            <h2>Stepping off the beaten track</h2>
                            <p>We love new stuff and we are not afraid of experiments and challenges. Yet, we do not follow trends blindly or simply play by the book. Creating our own products, we have discovered the importance of sensible risk planning, reliable estimates, and general project responsibility.
                            </p>
                            <NavLink className="btn" to={routes.career}>We're hiring <i className="fa fa-caret-right"></i></NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-photo section-photo-right">
                <div className="section-image right" style={{backgroundImage: `url('img/photos/founders.jpg')`}}>
                </div>
                <div className="wrapper">
                    <div className="container-resp">
                        <div className="col1-2 section-photo-content">
                            <h2>Solid foundation</h2>
                            <p>10A was founded by specialists in their respective fields: a manager and a developer. So we have a broad understanding of both our clients' businesses and the needs of our team.
                            </p>
                            <div className="founders">
                                <div className="name-linkedin">
                                    <a target="_blank" href="https://www.linkedin.com/in/katarzynazalass/" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                                    <div>Katarzyna Zalass <strong>Co-Founder | Digital Transformation Architect</strong></div>
                                </div>
                                <div className="name-linkedin">
                                    <a target="_blank" href="https://www.linkedin.com/in/tknyziak/" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                                    <div>Tomasz Knyziak <strong>Co-Founder | Software Architect and Developer</strong></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TrustedBy />
        </>
    )
}

export default About
