import React, { useRef } from 'react';
import { portfolioData } from '../data/portfolioData';
import { Fade } from 'react-slideshow-image';

const PortfolioItem = ({item}) => {
    return(<>
        <div className="project-image" style={{
            backgroundImage: `url('${item.image}')`,
            backgroundColor: `${item.background_color}`}}
        />
        <div className="wrapper" >
            <div className='project-content'>
                <img src={item.logo} alt={`${item.name} logo`} />
                <p>{item.desc}
                </p>
            </div>
        </div>
    </>)
}

function Portfolio() {

    const fader = useRef();

    const nextSlide = () => {   
        fader.current.goNext();
    };

    const prevSlide = () => {
        fader.current.goBack();
    };

    const startRandomIndex = (portfolioData) => {
        return Math.ceil(Math.random() * portfolioData.length - 1)
    };

    const renderPortfolioItems = (portfolioData) => {
        const items = portfolioData.map((item, index)=>(
            <PortfolioItem item={item} key={`portfolio-${index}`}/>
        ));
        return items
    };

    return (
        <div className="portfolio">
            <div className="wrapper">
                <h2>Some of our projects</h2>
            </div>
            <div className="projects">
                <Fade 
                    cssClass={"project"}
                    arrows={false}
                    ref={fader}
                    transitionDuration={500}
                    defaultIndex={startRandomIndex(portfolioData)}
                    ease={'ease-out'}
                    autoplay={false}
                >
                    {renderPortfolioItems(portfolioData)}
                </Fade>
            </div>
            <div className="wrapper">
                <div className="portfolio-nav">
                    <button onClick={prevSlide} className="porftolio-btn-prev"><i className="fa fa-caret-left"></i></button>
                    <span></span>
                    <button onClick={nextSlide} className="porftolio-btn-next"><i className="fa fa-caret-right"></i></button>
                </div>
            </div>
        </div>
    )
}

export default Portfolio
