import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

//Utils
import {mapStyles} from '../utils/mapStyles';

const marker = { latitude: 50.050813902193647, longitude: 19.969183890072095 };

const MapContainer = ({google}) => {
    return ( 
    <div className="map">
        <Map 
            google={google} 
            zoom={15}
            styles={mapStyles}
            initialCenter={{ lat: 50.050813902193647, lng: 19.969183890072095}}
            disableDefaultUI={false}
            mapTypeControl={false}
        >

            <Marker 
                name="10a"
                position={{lat:marker.latitude,lng:marker.longitude}}
                icon={{
                    url: 'img/pin.svg',
                    anchor: new google.maps.Point(0,100),
                    scaledSize: new google.maps.Size(100,100)
                }}
                onClick={()=>{window.open('https://www.google.com/search?tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=APq-WBt9RfvXmdFdSYz6AhRAjYjtNNrKqw:1644499359884&q=10a+krak%C3%B3w&rflfq=1&num=10&ved=2ahUKEwj1-7bBnfX1AhXNFXcKHQxXAV0QtgN6BAgREAU#rlfi=hd:;si:18096181307693565457;mv:[[50.055603943019484,19.986399646845932],[50.04438834406664,19.95421313866722],null,[50.04999647117673,19.970306392756576],16]', '_blank', 'noopener,noreferrer')}}
            >
            </Marker>
        </Map>
    </div>
    )
}
 
export default GoogleApiWrapper({apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, language:'polish'})(MapContainer);