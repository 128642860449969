import React from 'react'
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import Layout from './containers/Layout';
import Home from './Views/Home';
import {routes} from './routes/index';
import About from './Views/About';
import Career from './Views/Career';
import Wedo from './Views/Wedo';
import Contact from './Views/Contact';
import ScrollHandler from './scrollHandler'
import AnimatedSwitch from './utils/AnimatedSwitch';
import './App.css'

function App() {
    return (
        <BrowserRouter>
        <ScrollHandler/>
            <Layout>
                <Content />
            </Layout>
        </BrowserRouter>
    )
}

const Content = withRouter(({history, location}) => (
    <>
        <AnimatedSwitch location={location} history={history}>
            <Route exact path={routes.home} component={Home} />
            <Route exact path={routes.about} component={About} />
            <Route exact path={routes.career} component={Career} />
            <Route exact path={routes.wedo} component={Wedo} />
            <Route exact path={routes.contact} component={Contact} />
        </AnimatedSwitch>
    </>
))

export default App
