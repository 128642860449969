import React from 'react'
import { NavLink } from 'react-router-dom';
import { routes } from '../routes';
import TrustedBy from '../components/TrustedBy';
import WeAreExperts from '../components/WeAreExperts';
import Portfolio from '../components/Portfolio';
import { Slide } from 'react-slideshow-image';
import "react-slideshow-image/dist/styles.css";
import { Link } from 'react-scroll'

const images = [
    { url: "img/photos/1.jpg" },
    { url: "img/photos/2.jpg" },
    { url: "img/photos/3.jpg" },
    { url: "img/photos/4.jpg" },
    { url: "img/photos/5.jpg" },
    { url: "img/photos/6.jpg" },
  ];

function Home() {
 
    return (
        <>
            <div className="home-hero">
                <div className="home-hero-content">
                    <NavLink to="/"><img src="img/10a-logo-white.svg" className="logo" alt="10A Logo" /></NavLink>
                    <div className="heading-group">
                        <h1>Experts in dedicated solutions</h1>
                        <h4>Delivering human-centered digital products</h4>
                    </div>
                    <Link to={'home'} className="btn" spy={true} smooth={true} duration={500}>Read more <i className="fa fa-caret-down"></i></Link>
                </div>
                <div className="main-ilu-container">
                    <div className="main-ilu">
                        <img src="img/main-ilu-a.svg" alt="10A HQ Illustration A" className="main-ilu-img-a" draggable="false" />
                        <img src="img/main-ilu-0.svg" alt="10A HQ Illustration 0" className="main-ilu-img-0" draggable="false" />
                        <img src="img/main-ilu-1.svg"  alt="10A HQ Illustration 1" className="main-ilu-img-1" draggable="false" />
                        <img src="img/main-ilu.svg"   alt="10A HQ Illustration" className="main-ilu-img" draggable="false" />
                    </div>
                </div>
            </div>

            <div className="section-photo section-photo-right" id="home" name="home">
                <div className="slider">
                    <Slide
                        arrows ={false}
                        cssClass="slider-wrapp"
                        duration={6000}
                        easing="ease"
                        transitionDuration={500}
                        pauseOnHover={false}
                    >
                        {images.map((image, index) => (
                            <div className="each-slide" key={index}>
                                <div style={{'backgroundImage': `url(${image.url})`}}>
                            </div>
                        </div>
                        ))}
                    </Slide>
                </div>


                <div className="wrapper">
                    <div className="container-resp">
                    <div className="col1-2 section-photo-content">
                        <h2>Hi there!</h2>
                        <p>We’re a Software House focused on developing high-quality digital solutions. We've spent more than 10 years providing a vast collection of IT services, as well as producing and scaling our in-house products. Using gained experience and our expertise, we deliver amazing digital solutions and effective teams tailored for you.
                        </p>
                        <div className="tags">
                            <div><span role="img" aria-label='emoji'>🧑‍🚀</span> 25+ Humans</div>
                            <div><span role="img" aria-label='emoji'>🐕</span> 4+ Dogs</div>
                            <div><span role="img" aria-label='emoji'>🏙</span> Krakow, Poland</div>️
                        </div>
                        <NavLink className="btn" to={routes.about}>More about us <i className="fa fa-caret-right"></i></NavLink>
                    </div>
                    </div>
                </div>
                 
            </div>

            <TrustedBy />
            <WeAreExperts />
            <Portfolio />
        </>
    );
}

export default Home;
