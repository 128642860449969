export const TrustedUsData = [
    {
        id: 1,
        src: 'img/logos/saint-gobain.svg',
        alt: 'Saint Gobain'
    },
    {
        id: 2,
        src: 'img/logos/suzuki.svg',
        alt: 'Suzuki'
    },
    {
        id: 3,
        src: 'img/logos/lg.svg',
        alt: 'LG'
    },
    {
        id: 4,
        src: 'img/logos/budimex.svg',
        alt: 'Budimex'
    },
    {
        id: 5,
        src: 'img/logos/cash&carry.svg',
        alt: 'Cash&Carry'
    },
    {
        id: 6,
        src: 'img/logos/delikatesy.svg',
        alt: 'Delikatesy'
    },
    {
        id: 7,
        src: 'img/logos/eurocash-grupa.svg',
        alt: 'Eurocash grupa'
    },
    {
        id: 8,
        src: 'img/logos/milwaukee.svg',
        alt: 'Milwaukee'
    },
    {
        id: 9,
        src: 'img/logos/monnari.svg',
        alt: 'Monnari'
    },
    {
        id: 10,
        src: 'img/logos/RYOBI.svg',
        alt: 'RYOBI'
    }

]